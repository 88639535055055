import { Card, CardContent, StepLabel, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { AiFillInfoCircle } from "react-icons/ai";
import Lottie from "react-lottie-player";
import { useLocation, useParams } from "react-router-dom";
import failure from "../assets/failure.json";
import succeess from "../assets/success.json";
import * as CommonMethod from "../Utils/CommonMethods";
import * as API from "../Utils/Services";
import "./../App.css";
import brokerLogo from "./../broker-logo.png";
import { TfiNewWindow } from "react-icons/tfi";
function AddFunds() {
  let location = useLocation();
  const ref = useRef(null);
  const [time, setTime] = useState(0);
  const [upiSuccess, setUPISuccess] = useState("");
  const [lefttime, setLefttime] = useState();
  const [data, setData] = useState();
  let params = useParams();
  let postHeader = {
    UserName: localStorage.getItem("clientID"),
    Authorization: `Bearer ` + localStorage.getItem("authToken"),
    "P-Appname": "space",
  };
  let getHeader = {
    "x-authorization-token": localStorage.getItem("authToken"),
  };
  useEffect(() => {
    if (
      upiSuccess === "PENDING" &&
      !CommonMethod.isNullOrUndefined(time) &&
      lefttime !== 0
    )
      ref.current = setTimeout(fetchData, 5000);
  }, [time]);
  useEffect(() => {
    fetchData();
  }, []);
  function fetchData() {
    let payLoad = {
      clientId: location?.state?.clientId,
      customerReferenceNo: "",
      orderNo: parseInt(location?.state?.orderNo),
      upiTransactionId: location?.state?.upiTransactionId,
    };
    API.PostMethod("v2/hdfc/transactionStatusEnquiry",
      payLoad,
      postHeader
    )
      .then((res) => {
        setUPISuccess(res?.data?.transactionStatus);
        setData(res?.data);
        setTime(time + 5);
      })
      .catch((err) => { });
  }

  const renderTime = ({ remainingTime }) => {
    const secondsToMinSecPadded = (time) => {
      const minutes = "0" + Math.floor(time / 60);
      const seconds = "0" + (time - minutes * 60);
      return minutes.substr(-2) + ":" + seconds.substr(-2);
    };
    setLefttime(remainingTime);
    if (remainingTime === 0) {
      return <div className="timer">Timeout !</div>;
    }

    return (
      <div className="timer">
        <div className="text">Left</div>
        <div className="value">{secondsToMinSecPadded(remainingTime)}</div>
        <div className="text">minutes</div>
      </div>
    );
  };
  return (
    <div className="App">
      <div className="App-header">
        <div className="d-flex flex-row pkt-height-vh-90 pkt-width-percentage-100 justify-content-center align-items-center">
          <Card className="d-flex pkt-height-percentage-100 pkt-overflow-y-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pkt-no-box-shadow">
            <CardContent className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center pkt-padding-25">
              {" "}
              <div className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center">
                <img
                  src={brokerLogo}
                  alt="logo"
                  style={{ height: "60px", width: "180px" }}
                />
                <div className="pkt-margin-top-15 pkt-font-bold pkt-font-size-22">
                  Amount Payable :{" "}
                  {/* <span>
                    {" "}
                    <Icon className="pkt-margin-bottom-5 pkt-margin-right-10 ">
                      {" "}
                      <TbCurrencyRupee></TbCurrencyRupee>
                    </Icon>
                  </span> */}
                  ₹ {location?.state?.amount}
                </div>
                <hr
                  className="pkt-width-percentage-100"
                  style={{ borderTop: "2px dashed #5300F4" }}
                ></hr>
              </div>
              <div
                className="d-flex flex-column pkt-width-percentage-100 align-items-start justify-content-around"
                style={{ flex: 1 }}
              >
                <div className="d-flex flex-column  pkt-width-percentage-100 align-items-start ">
                  <span className="pkt-font-bold pkt-font-size-22">
                    Payment in Process
                  </span>
                  <Stepper
                    className="pkt-margin-top-10 pkt-width-percentage-100 "
                    activeStep={upiSuccess === "SUCCESS" ? 2 : 1}
                    orientation="vertical"
                  >
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel
                          sx={{ fontFamily: "sans-serif" }}
                          optional={
                            index === 0 ? (
                              <Typography>{location?.state?.upiID}</Typography>
                            ) : null
                          }
                        >
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div className="pkt-width-percentage-100 pkt-min-height-100 pkt-margin-top-10">
                    <Card
                      className="pkt-margin-top-5 pkt-width-percentage-100 pkt-height-percentage-100 align-items-start justify-content-around d-flex flex-column pkt-padding-10"
                      sx={{
                        background: "rgb(255 220 184 / 97%)",
                        boxShadow: 0,
                        borderRadius: "10px",
                      }}
                    >
                      <div className="d-flex flex-row justify-content-start align-items-start">
                        {" "}
                        <div>
                          <AiFillInfoCircle></AiFillInfoCircle>
                        </div>
                        <span className="pkt-font-size-18 pkt-margin-left-8">
                          Please Pay via
                        </span>
                      </div>
                      <div
                        className="pkt-font-size-18 pkt-padding-10 pkt-font-bold pkt-margin-left-16"
                        style={{ textAlign: "start" }}
                      >
                        <span className="pkt-font-size-18  pkt-font-bold">
                          {location?.state?.bank} :{" "}
                          <span className="pkt-font-size-13">XXXXXXXXX</span>
                          {location?.state?.bankAccount.slice(-4)}
                        </span>
                      </div>
                    </Card>
                  </div>{" "}
                  <hr
                    className="pkt-width-percentage-100  pkt-margin-top-30"
                    style={{ borderTop: "2px dashed #5300F4" }}
                  ></hr>
                </div>
                <div className="d-flex justify-content-start pkt-padding-20 align-items-center pkt-width-percentage-100">
                  {" "}
                  {lefttime === 0 ? (
                    <div className="d-flex flex-column pkt-width-percentage-100  align-items-center">
                      <Lottie
                        loop={false}
                        animationData={failure}
                        play
                        style={{ width: 150, height: 150 }}
                      />

                      <span
                        style={{ color: "#dc3545" }}
                        className="pkt-font-bold pkt-margin-top-20 pkt-font-size-20"
                      >
                        Timeout!
                      </span>

                      <span
                        style={{ color: "#dc3545" }}
                        className="pkt-margin-top-40 pkt-font-size-14"
                      >
                        Please close the window and try again!
                      </span>
                    </div>
                  ) : upiSuccess === "SUCCESS" ? (
                    <div className="d-flex flex-column pkt-width-percentage-100  align-items-center">
                      <Lottie
                        loop={false}
                        animationData={succeess}
                        play
                        style={{ width: 150, height: 150 }}
                      />

                      <span
                        style={{ color: "#00b7b1" }}
                        className="pkt-font-bold pkt-margin-top-10 pkt-font-size-20"
                      >
                        SUCCESS
                      </span>

                      <span
                        style={{ color: "#00b7b1" }}
                        className="pkt-margin-top-10 pkt-font-bold pkt-font-size-20"
                      >
                        Deposited Amount INR {data?.amount}
                      </span>

                      <span
                        style={{ color: "#00b7b1" }}
                        className="pkt-margin-top-20 pkt-font-size-18"
                      >
                        Reference No. : {data?.customerReferenceNumber}
                      </span>
                      <div
                        className="d-flex flex-row align-items-center pkt-margin-top-8 pkt-cursor-pointer"
                        style={{
                          borderBottom: "1px dashed ",
                          color: "#5300f4 ",
                        }}
                        onClick={() => {
                          window.location.replace(
                            "https://pacetrader.pacefin.in/funds/info"
                          );
                        }}
                      >
                        <span>Go to pace trader</span>{" "}
                        <div className="d-flex align-items-center jsutify-content-center pkt-margin-left-6">
                          {" "}
                          <TfiNewWindow />{" "}
                        </div>
                      </div>
                      <span
                        style={{ color: "#dc3545" }}
                        className="pkt-margin-top-10 pkt-font-size-14"
                      >
                        Please close the window!
                      </span>
                    </div>
                  ) : upiSuccess === "REJECTED" || upiSuccess === "FAILED" ? (
                    <div className="d-flex flex-column pkt-width-percentage-100  align-items-center">
                      <Lottie
                        loop={false}
                        animationData={failure}
                        play
                        style={{ width: 150, height: 150 }}
                      />

                      <span
                        style={{ color: "#dc3545" }}
                        className="pkt-font-bold pkt-margin-top-20 pkt-font-size-20"
                      >
                        {upiSuccess === "REJECTED"
                          ? "Payment Rejected"
                          : upiSuccess === "FAILED"
                            ? "Payment Failed"
                            : null}
                      </span>
                      <div
                        className="d-flex flex-row align-items-center pkt-margin-top-8 pkt-cursor-pointer"
                        style={{
                          borderBottom: "1px dashed ",
                          color: "#5300f4 ",
                        }}
                        onClick={() => {
                          window.location.replace(
                            "https://pacetrader.pacefin.in/funds/info"
                          );
                        }}
                      >
                        <span>Go to pace trader</span>{" "}
                        <div className="d-flex align-items-center jsutify-content-center pkt-margin-left-6">
                          {" "}
                          <TfiNewWindow />{" "}
                        </div>
                      </div>
                      <span
                        style={{ color: "#dc3545" }}
                        className="pkt-margin-top-28 pkt-font-size-14"
                      >
                        Please close the window and try again!
                      </span>
                    </div>
                  ) : (
                    <CountdownCircleTimer
                      isPlaying
                      size={160}
                      duration={300}
                      colors={["#5322F0"]}
                      strokeWidth={7}
                      onComplete={() => ({ shouldRepeat: false })}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  )}{" "}
                  {lefttime === 0 ? null : upiSuccess === "SUCCESS" ||
                    upiSuccess === "REJECTED" ||
                    upiSuccess === "FAILED" ? null : (
                    <div className="pkt-margin-left-15 pkt-font-size-18">
                      Approve the payment request before it times out.
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

const steps = [
  { label: "Payment request is sent to your UPI" },
  { label: "Open UPI app to approve payment request from PACE." },
];

export default AddFunds;
