import React from "react";
import { Button } from "@mui/material";
import Lottie from "react-lottie-player";
import profile from "../assets/profile.json";

export default function RedirectPage() {
  return (
    <div className="App">
      <div className="App-header">
        <div className="d-flex flex-column pkt-height-vh-90 pkt-width-percentage-100 justify-content-center align-items-center">
          <Lottie
            loop={true}
            animationData={profile}
            play
            style={{ width: 350, height: 350 }}
          />
          <Button
            variant="outlined"
            className="pkt-margin-top-24"
            onClick={() => {
              window.location.replace("https://pacetrader.pacefin.in/");
            }}
          >
            Go to Pace Trader
          </Button>
        </div>
      </div>
    </div>
  );
}
