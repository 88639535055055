import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import { MdOutlineAdd } from "react-icons/md";
import { TbCurrencyRupee } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Loader from "../CommonComponents/Loader";
import * as CONSTANTS from "../Utils/constant";
import "./../App.css";
import brokerLogo from "./../broker-logo.png";
import * as CommonMethods from "./../Utils/CommonMethods";
import * as API from "./../Utils/Services";
function Payment() {
  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [paymentMode, setPaymentMode] = useState("UPI");
  const [profileData, setProfileData] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [upiVerified, setUPIVerified] = useState(false);
  const [upiLoading, setUPILoading] = useState(false);
  const [netLoading, setNetLoading] = useState(false);
  const [upiStart, setUPIStart] = useState(true);
  const [upiError, setUPIError] = useState(false);
  const [bankCode, setBankCode] = useState(null);
  const [payerName, setPayerName] = useState("");
  const [upiErrormsg, setUPIErrormsg] = useState("");
  const [upiOrderId, setUPIOrderId] = useState(null);
  const [errorMsg, setErrorMSg] = useState(null);
  const [netBankingError, setnetBankingError] = useState(null);
  const [upiLoadingSubmit, setUpiLoadingSubmit] = useState(false);
  const [initalAccount, setInitalAccount] = useState({});
  const [selectedBank, setSelectedBank] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [upiBankAccountNumber, setUpiBankAccountNumber] = useState('');
  let params = useParams();
  let postHeader = {
    UserName: localStorage.getItem("clientID"),
    Authorization: `Bearer ` + params?.authToken,
    "P-Appname": "space",
    'P-DeviceType': "web",

  };
  let cashFreeHeader = {
    UserName: localStorage.getItem("clientID"),
    Authorization: `Bearer ` + params?.authToken,
    "P-Appname": "space",
  };
  let getHeader = {
    "x-authorization-token": params?.authToken,
  };
  let navigate = useNavigate();
  const paymentForm = useFormik({
    initialValues: {
      paymentMode: "UPI",
      bankAccount: "",
      amount: params.hasOwnProperty("value") ? params?.value : '',
      upiID: "",
    },
    validationSchema: Yup.object({
      bankAccount: Yup.string().required("Bank Account is required!"),
      amount: Yup.string().required("Amount is required!"),
      // upiID: Yup.string().when("paymentMode", {
      //   is: "UPI",
      //   then: Yup.string().required("UPI ID is required!"),
      //   otherwise: Yup.string(),
      // }),
    }),
    onSubmit: (values) => {
      setUpiLoadingSubmit(true);

      if (values.paymentMode === "UPI") {
        let payLoad = {
          amount: parseFloat(paymentForm.values.amount),
          clientId: localStorage.getItem("clientID"),
          expiryValue: CONSTANTS.upiTimer,
          mccCode: 0,
          payerVirtualAddress: paymentForm.values.upiID,
          remarks: localStorage.getItem("clientID"),
          mebr: "MEBR",
          upiBankAccountNumber: upiBankAccountNumber,
          upiBankAccountIfsc: ifsc,
        };
        API.PostMethod("v2/hdfc/transactionRequest", payLoad, postHeader)
          .then((res) => {
            // setNetLoading(true);
            if (res?.data?.transactionStatus === "SUCCESS") {
              localStorage.setItem("upiId", paymentForm.values.upiID);
              setUpiLoadingSubmit(false);
              navigate("/add-funds", {
                state: {
                  clientId: localStorage.getItem("clientID"),
                  orderNo: res?.data?.orderNo,
                  upiTransactionId: res?.data?.upiTransactionRefernceId,
                  upiID: paymentForm.values.upiID,
                  amount: paymentForm.values.amount,
                  refId: res?.data?.additionalField6,
                  bankAccount: paymentForm.values.bankAccount,
                  bank: selectedBank,
                },
              });
            }
          })
          .catch((err) => {
            setUpiLoadingSubmit(false);
            console.log(err);
          });
      } else {
        setNetLoading(true);
        let payLoad = {
          clientId: localStorage.getItem("clientID"),
          customerDetails: {
            customerBankAccountNumber: paymentForm.values.bankAccount,
            customerBankCode: bankCode,
            customerBankIfsc: ifsc,
            customerEmail: profileData?.emailId,
            customerId: localStorage.getItem("clientID"),
            customerName: localStorage.getItem("clientID"),
            customerPhone: profileData?.phoneNumber,
          },
          orderAmount: parseFloat(paymentForm.values.amount),
          orderCurrency: "INR",
          orderMeta: {
            notifyUrl: "",
            paymentMethods: "cc,dc,nb,upi",
            returnUrl: "",
          },
          orderTags: {
            paymentType: "",
            userId: "",
          },
          order_id: "",
        };

        API.PostMethod("v1/cashfree/createOrder", payLoad, cashFreeHeader)
          .then((res) => {
            if (res?.data?.paymentsSessionId) {
              let xy = {
                clientId: res.data?.customerDetails?.customerId,
                paymentSessionId: res?.data?.paymentsSessionId,
                paymentMethod: {
                  netBanking: {
                    channel: "link",
                    netBankingBankCode: bankCode,
                  },
                },
              };
              API.PostMethod("v1/cashfree/netBanking", xy, cashFreeHeader).then(
                (result) => {
                  if (result?.data?.data?.url.length != 0) {
                    setUpiLoadingSubmit(false);
                    window.location.replace(result?.data?.data?.url);
                  } else {
                    setnetBankingError('Net banking unavailable. Try after sometime!');
                    setUpiLoadingSubmit(false);
                    setNetLoading(false);
                  }
                }
              );
            }
          })
          .catch((err) => {
            setUpiLoadingSubmit(false);
            setNetLoading(false);
            console.log(err);
          });
      }
    },
  });
  const handleClick = (e) => {
    if (e.currentTarget.innerText === "1,000") {
      paymentForm.setFieldValue("amount", 1000);
    } else if (e.currentTarget.innerText === "2,000") {
      paymentForm.setFieldValue("amount", 2000);
    } else if (e.currentTarget.innerText === "5,000") {
      paymentForm.setFieldValue("amount", 5000);
    }
  };

  function verifyUPI() {
    setUPIStart(false);
    setUPILoading(true);
    let payLoad = {
      clientId: localStorage.getItem("clientID"),
      payerVirtualAddress: paymentForm.values.upiID,
      transactionStatus: "T",
    };
    API.PostMethod("v2/hdfc/checkVPA", payLoad, postHeader)
      .then((res) => {
        setUPILoading(false);
        if (res?.data?.status1 === "VE" || res?.data?.status2 === "VE") {
          setPayerName(res?.data?.payerName);
          setUPIOrderId(res?.data?.merchantRefNo);
          setUPIVerified(true);
        } else {
          setUPIErrormsg("Please check UPI ID!");
        }
      })
      .catch((err) => {
        setUPILoading(false);
        setUPIErrormsg(err?.response?.statusText);
      });
  }
  function getProfile() {
    setPageLoading(true);
    API.PostMethod(
      "v1/getProfile", { clientId: params?.clientId }, postHeader
    )
      .then((res) => {
        if (res?.data != null) {
          setProfileData(res?.data);
          API.PostMethod(
            "v1/getAllBankAccounts", { clientId: params?.clientId }, postHeader
          ).then((res) => {
            if (res?.data != null) {
              paymentForm.setFieldValue(
                "bankAccount",
                res?.data?.bankAccounts[0].bankAccountNumber
              );
              setSelectedBank(res?.data?.bankAccounts[0].bankName);
              setAccounts(res?.data?.bankAccounts);
              setIfsc(res?.data?.bankAccounts[0].ifsc);
              setUpiBankAccountNumber(res?.data?.bankAccounts[0].bankAccountNumber)
              setInitalAccount(res?.data?.bankAccounts[0]);
              let code = CONSTANTS.bank_data.filter(
                (item) =>
                  item?.Bank.toLocaleLowerCase() ===
                  res?.data?.bankAccounts[0].bankName.toLocaleLowerCase()
              );

              setBankCode(code[0]?.payment_code);

              setPageLoading(false);
            }
          });
        }
      })
      .catch((err) => {
        setPageLoading(false);
        setPageError(true);
      });
  }

  const bankAccount = (e) => {
    let b_a = accounts.filter(
      (item) => item.bankAccountNumber === e.target.value
    );
    let code = CONSTANTS.bank_data.filter(
      (item) => item?.Bank === b_a[0].bankName
    );
    if (paymentMode != "UPI") setBankCode(code[0].payment_code);
    setSelectedBank(b_a[0].bankName);
    setIfsc(b_a[0].ifsc);
    setUpiBankAccountNumber(b_a[0].bankAccountNumber)
    paymentForm.setFieldValue("bankAccount", e.target.value);
  };
  useEffect(() => {
    if (params?.authToken) {

      localStorage.setItem("authToken", params?.authToken);
      localStorage.setItem("clientID", params?.clientId);
    }
    getProfile();
    // getAccounts();
  }, []);
  return (
    <div className="App">
      <div className="App-header">
        <div className="d-flex flex-row  pkt-width-percentage-100 justify-content-center align-items-center">
          {pageLoading ? (
            <Loader />
          ) : pageError ? (
            <div style={{ color: "black" }}> Profile Not found!</div>
          ) : (
            <Card className="d-flex pkt-height-percentage-100 pkt-overflow-y-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pkt-no-box-shadow">
              <CardContent className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center ">
                {" "}
                <div className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center">
                  <img
                    src={brokerLogo}
                    alt="logo"
                    style={{ height: "60px", width: "180px" }}
                  />
                  <div className="pkt-margin-top-15 pkt-font-size-20 pkt-font-bold ">
                    Deposit Funds
                  </div>{" "}
                  <Card
                    className="pkt-margin-top-15 pkt-width-percentage-100 "
                    sx={{
                      background: "rgba(83, 34, 240, 0.1)",
                      boxShadow: 0,
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent className="d-flex flex-row pkt-width-percentage-100">
                      <div className="d-flex flex-column pkt-width-percentage-40">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <div>Name</div>
                          <div>:</div>
                        </div>

                        <div
                          className="d-flex flex-row"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>Client ID</div>
                          <div>:</div>
                        </div>
                      </div>
                      <div className="d-flex flex-column pkt-width-percentage-60 pkt-margin-left-15">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <div>{profileData?.name}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <div>{profileData?.clientId}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div
                  className="d-flex flex-column pkt-margin-top-10 pkt-width-percentage-100 align-items-start "
                  style={{ flex: 1 }}
                >
                  <div className="d-flex flex-column pkt-margin-top-10 pkt-width-percentage-100 align-items-start ">
                    <Formik onSubmit={paymentForm.onSubmit}>
                      <Form className="d-flex flex-column col-12 align-items-start">
                        <FormControl className="d-flex flex-row pkt-width-percentage-100 justify-content-start align-items-center">
                          <span className="pkt-font-bold  pkt-margin-right-12">Payment Mode</span>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="UPI"
                            className="d-flex flex-row  justify-content-start"
                            name="radio-buttons-group"
                            onChange={(e) => {
                              paymentForm.setFieldValue(
                                "paymentMode",
                                e.target.value
                              );
                              setnetBankingError(null)
                              setPaymentMode(e.target.value);
                              setUPIErrormsg(null);
                              setUPIStart(true);
                              setUPIError(false);
                              setUPILoading(false);
                              setUPIVerified(false);

                              if (e.target.value === "netBanking") {
                                setUPIVerified(true);
                                let b_a = accounts.filter(
                                  (item) =>
                                    item.bankAccountNumber ===
                                    paymentForm.values.bankAccount
                                );
                                let code = CONSTANTS.bank_data.filter(
                                  (item) => item?.Bank === b_a[0].bankName
                                );
                                if (paymentMode != "UPI")
                                  setBankCode(code[0].payment_code);
                              } else {
                                setUPIVerified(false);
                                setUpiLoadingSubmit(false);
                              }
                            }}
                          >
                            <FormControlLabel
                              value="UPI"
                              control={<Radio />}
                              label="UPI"
                              className="d-flex col-12  col-sm-4 col-md-4 col-lg-4 col-xl-4"
                            />
                            {/* <FormControlLabel
                              value="netBanking"
                              // disabled={true}
                              control={<Radio />}
                              label="Net Banking (Rs.9 + GST)"
                            /> */}
                          </RadioGroup>
                        </FormControl>
                        <span className="pkt-margin-top-10 pkt-font-bold">
                          Bank Account
                        </span>
                        <div className=" pkt-width-percentage-100">
                          <FormControl className="d-flex col-12">
                            <TextField
                              className=" pkt-width-percentage-100 pkt-height-45"
                              style={{ textAlign: "start" }}
                              variant="outlined"
                              defaultValue={initalAccount?.bankAccountNumber}
                              InputProps={{
                                style: {
                                  height: "45px",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#5300F4",
                                  },
                                },
                              }}
                              placeholder="Bank Account"
                              value={paymentForm.values.bankAccount}
                              onChange={bankAccount}
                              error={
                                paymentForm.touched.bankAccount &&
                                  paymentForm.errors.bankAccount
                                  ? true
                                  : false
                              }
                              onBlur={paymentForm.handleBlur}
                              select
                            >
                              {CommonMethods.isNullOrUndefined(accounts) ? (
                                <MenuItem value="noaccountfound">
                                  No Bank Account found
                                </MenuItem>
                              ) : (
                                accounts.map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={
                                        accounts[index]?.bankAccountNumber
                                      }
                                    >
                                      {item?.bankName} -{" "}
                                      {item?.bankAccountNumber}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </TextField>
                            <div className="d-flex align-items-start">
                              {paymentForm.touched.bankAccount &&
                                paymentForm.errors.bankAccount ? (
                                <span className="pkt-error">
                                  {paymentForm.errors.bankAccount}
                                </span>
                              ) : null}
                            </div>{" "}
                          </FormControl>
                        </div>
                        <span className="pkt-margin-top-15 pkt-font-bold">
                          Amount
                        </span>
                        <div className=" pkt-width-percentage-100 ">
                          <FormControl className="col-12">
                            {" "}
                            <TextField
                              className=" pkt-width-percentage-100 pkt-height-45"
                              type="number"
                              value={paymentForm.values.amount}
                              onChange={(e) => {
                                paymentForm.setFieldValue(
                                  "amount",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: {
                                  height: "45px",
                                },
                                startAdornment: (
                                  <Icon className="pkt-margin-bottom-5 pkt-margin-right-10">
                                    {" "}
                                    <TbCurrencyRupee></TbCurrencyRupee>
                                  </Icon>
                                ),
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#5300F4",
                                  },
                                },
                              }}
                              error={
                                paymentForm.touched.amount &&
                                  paymentForm.errors.amount
                                  ? true
                                  : false
                              }
                            ></TextField>
                            <div className="d-flex align-items-start">
                              {paymentForm.touched.amount &&
                                paymentForm.errors.amount ? (
                                <span className="pkt-error">
                                  {paymentForm.errors.amount}
                                </span>
                              ) : null}
                            </div>{" "}
                          </FormControl>
                        </div>{" "}
                        <div className="d-flex flex-row flex-wrap pkt-margin-top-15">
                          <Chip
                            label="1,000"
                            variant="outlined"
                            value="1000"
                            onClick={handleClick}
                            icon={<MdOutlineAdd style={{ color: "#5322F0" }} />}
                            sx={{
                              background: "rgba(83, 34, 240, 0.1)",
                              color: "#5322F0",
                              border: "0px",
                            }}
                          ></Chip>
                          <Chip
                            className="pkt-margin-left-15"
                            label="2,000"
                            variant="outlined"
                            onClick={handleClick}
                            icon={<MdOutlineAdd style={{ color: "#5322F0" }} />}
                            sx={{
                              background: "rgba(83, 34, 240, 0.1)",
                              color: "#5322F0",
                              border: "0px",
                            }}
                          ></Chip>{" "}
                          <Chip
                            className="pkt-margin-left-15"
                            label="5,000"
                            variant="outlined"
                            onClick={handleClick}
                            icon={<MdOutlineAdd style={{ color: "#5322F0" }} />}
                            sx={{
                              background: "rgba(83, 34, 240, 0.1)",
                              color: "#5322F0",
                              border: "0px",
                            }}
                          ></Chip>
                        </div>
                        {paymentMode === "UPI" ? (
                          <React.Fragment>
                            <span className="pkt-margin-top-25 pkt-font-bold">
                              Virtual Payment Address (UPI)
                            </span>
                            <div className=" pkt-width-percentage-100 ">
                              <TextField
                                className=" pkt-width-percentage-100 pkt-height-45"
                                InputProps={{
                                  style: {
                                    height: "45px",
                                  },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                      borderColor: "#5300F4",
                                    },
                                  },
                                }}
                                placeholder="eg:abc123@okaxis"
                                onChange={(e) => {
                                  setNetLoading(false);
                                  setUPIErrormsg(null);
                                  setUPIStart(true);
                                  setUPIError(false);
                                  setUPILoading(false);
                                  setUPIVerified(false);
                                  paymentForm.setFieldValue(
                                    "upiID",
                                    e.target.value
                                  );
                                }}
                                value={paymentForm.values.upiID}
                              ></TextField>
                            </div>
                            <div
                              className="d-flex pkt-width-percentage-100 align-items-center justify-content-between  pkt-margin-top-10 pkt-margin-right-10"
                              style={{ color: "#5322F0" }}
                            >
                              {upiVerified ? (
                                <div
                                  style={{ color: "black" }}
                                  className=" d-flex align-items-start justify-content-start pkt-font-bold"
                                >
                                  Registered Name : {payerName}
                                </div>
                              ) : (
                                <div></div>
                              )}{" "}
                              {!CommonMethods.isNullOrUndefined(upiErrormsg) ? (
                                <div
                                  style={{ width: "100vw" }}
                                  className="d-flex align-items-start justify-content-start pkt-error pkt-font-bold"
                                >
                                  {upiErrormsg}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <Chip
                                disabled={
                                  CommonMethods.isNullOrUndefined(
                                    paymentForm.values.upiID
                                  ) ||
                                    !CommonMethods.isNullOrUndefined(
                                      upiErrormsg
                                    ) ||
                                    upiLoading === true ||
                                    upiVerified === true
                                    ? true
                                    : false
                                }
                                label={
                                  upiLoading
                                    ? "Verifying"
                                    : upiVerified
                                      ? "Verified"
                                      : upiStart
                                        ? "Verify"
                                        : "Error"
                                }
                                variant="outlined"
                                onClick={verifyUPI}
                                sx={{
                                  background: "rgba(83, 34, 240, 0.1)",
                                  color: upiVerified
                                    ? "rgba(67,160,71)"
                                    : "#5322F0",
                                  border: "0px",
                                }}
                                onDelete={() => { }}
                                deleteIcon={
                                  upiLoading ? (
                                    <CircularProgress className="pkt-height-18 pkt-width-18 " />
                                  ) : upiVerified ? (
                                    <FcCheckmark className="pkt-height-20 pkt-width-20  pkt-font-bold" />
                                  ) : (
                                    <div></div>
                                  )
                                }
                              ></Chip>
                            </div>
                          </React.Fragment>
                        ) : null}
                      </Form>
                    </Formik>
                  </div>
                  <div className="pkt-width-percentage-100 pkt-margin-top-20 ">
                    {paymentMode === "UPI" ? (
                      <span
                        style={{ color: "#6c6c6c" }}
                        className="pkt-error d-flex pkt-width-percentage-100 align-items-start pkt-font-size-14 pkt-font-bold"
                      >
                        *Please verify UPI ID before proceeding.
                      </span>
                    ) : null}
                    {netBankingError != null ? <span

                      className="pkt-error d-flex pkt-width-percentage-100 align-items-start pkt-font-size-14 pkt-font-bold"
                    >{netBankingError}</span> : null}
                    <Button
                      className="pkt-width-percentage-100 pkt-margin-top-10 pkt-height-45"
                      variant="contained"
                      style={{
                        textAlign: "end",
                        background:
                          !upiVerified || upiLoadingSubmit
                            ? "rgba(0, 0, 0, 0.12)"
                            : "#5300F4",
                        // background: "#5300F4",
                      }}
                      onClick={() => {
                        paymentForm.handleSubmit();
                      }}
                      disabled={!upiVerified || upiLoadingSubmit}
                      type="submit"
                    >
                      {paymentMode === "netBanking" && netLoading === true ? (
                        <CircularProgress
                          className="pkt-height-23 pkt-width-23 "
                          sx={{ color: "white" }}
                        />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payment;
