import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import succeess from "../assets/success.json";
import failure from "../assets/failure.json";
import "./../App.css";
import brokerLogo from "./../broker-logo.png";
function StatusPage() {
  const [isSuccess, setIsSuccess] = useState(true);
  useEffect(() => {}, []);

  return (
    <div className="App">
      <div className="App-header">
        <div className="d-flex flex-row pkt-height-vh-90 pkt-width-percentage-100 justify-content-center align-items-center">
          <Card className="d-flex pkt-height-percentage-100 pkt-overflow-y-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pkt-no-box-shadow">
            <CardContent className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center pkt-padding-25">
              {" "}
              <div className="d-flex flex-column pkt-width-percentage-100 justify-content-start align-items-center">
                <img
                  src={brokerLogo}
                  alt="logo"
                  style={{ height: "60px", width: "180px" }}
                />
                <div className="pkt-margin-top-15 pkt-font-size-20 pkt-font-bold ">
                  Payment Status
                </div>{" "}
                <hr
                  className="pkt-width-percentage-100  pkt-margin-top-15"
                  style={{ borderTop: "2px dashed #5300F4" }}
                ></hr>
              </div>
              <div
                className="d-flex flex-column pkt-width-percentage-100 pkt-margin-top-10 pkt-width-percentage-100 align-items-center  justify-content-between"
                style={{ flex: 1 }}
              >
                <div className="d-flex flex-column pkt-width-percentage-100  align-items-center">
                  <Lottie
                    loop={false}
                    animationData={isSuccess ? succeess : failure}
                    play
                    style={{ width: 150, height: 150 }}
                  />

                  <span
                    style={{ color: isSuccess ? "#00b7b1" : "#dc3545" }}
                    className="pkt-font-bold pkt-margin-top-20 pkt-font-size-20"
                  >
                    {isSuccess ? "SUCCESS" : "FAILED!"}
                  </span>
                  <span className="pkt-margin-top-20 pkt-font-bold pkt-font-size-20">
                    {isSuccess ? " Deposited Amount INR 15000" : ""}{" "}
                  </span>

                  <span className="pkt-margin-top-20 pkt-font-size-18">
                    {isSuccess ? "Reference No. : 5689436593" : ""}
                  </span>
                  <span className="pkt-margin-top-40 pkt-font-size-14">
                    Please close the window!
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default StatusPage;
