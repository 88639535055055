import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
//Get Method
export let GetMethod = async (baseUrl, header) => {
  let getResponse = await axios.get(baseUrl, {
    headers: header,
  });
  return getResponse ? getResponse.data : null;
};

//Post Method
export let PostMethod = async (url, data, header) => {
  let postData = await axios.post(baseUrl + url, data, {
    headers: header,
  });
  return postData ? postData.data : null;
};
