import React from "react";
import { Route, Routes } from "react-router-dom";
import AddFunds from "../Page/AddFunds";
import Payment from "../Page/Payment";
import StatusPage from "../Page/StatusPage";
import RedirectPage from "../Page/RedirectPage";
import AuthenticatedGuard from "../Utils/AuthGuard";
import ProfileNotFound from "../Page/ProfileNotFound";
function Routing() {
  return (
    <React.Suspense>
      <Routes>
        <Route path="/" element={<RedirectPage />} />
        <Route path="/profile-not-found" element={<ProfileNotFound />} />
        <Route element={<AuthenticatedGuard />}>
          <Route exact path="/:clientId/:authToken/:value?" element={<Payment />} />
          <Route exact path="/add-funds" element={<AddFunds />} />
        </Route>{" "}
        <Route exact path="/status" element={<StatusPage />} />
      </Routes>
    </React.Suspense>
  );
}

export default Routing;
