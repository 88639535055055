export const upiTimer = 5;

export const AUTH_Token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJibGFja2xpc3Rfa2V5IjoiOUNEMTI6dGVLWnczckJWend0d1Z0bGo4aEo5dyIsImNsaWVudF9pZCI6IjlDRDEyIiwiY2xpZW50X3Rva2VuIjoiamxTR01vTXJ6MktKd3JFZFliSW02dyIsImRldmljZSI6IndlYiIsImV4cCI6MTY3NzczMzEyNzI0NX0.sRvnG0tMMpA87gzwVDbrmd5mxoq5c7-vzYADuxFySbI";
export const bank_data = [
  {
    payment_code: 3003,
    Bank: "Axis Bank",
  },
  {
    payment_code: 3003,
    Bank: "AXIS BANK LTD",
  },
  {
    payment_code: 3005,
    Bank: "Bank of Baroda - Retail Banking",
  },
  {
    payment_code: 3005,
    Bank: "BANK OF BARODA",
  },
  {
    payment_code: 3006,
    Bank: "Bank of India",
  },
  {
    payment_code: 3010,
    Bank: "CSB Bank Limited",
  },
  {
    payment_code: 3012,
    Bank: "City Union Bank",
  },
  {
    payment_code: 3012,
    Bank: "CITY UNION BANK LIMITED",
  },
  {
    payment_code: 3016,
    Bank: "Deutsche Bank",
  },
  {
    payment_code: 3016,
    Bank: "DEUSTCHE BANK",
  },
  {
    payment_code: 3019,
    Bank: "Dhanlakshmi Bank",
  },
  {
    payment_code: 3019,
    Bank: "DHANALAKSHMI BANK",
  },
  {
    payment_code: 3020,
    Bank: "Federal Bank",
  },
  {
    payment_code: 3021,
    Bank: "HDFC Bank",
  },
  {
    payment_code: 3022,
    Bank: "ICICI Bank",
  }, {
    payment_code: 3022,
    Bank: "ICICI BANK LTD",
  },
  {
    payment_code: 3022,
    Bank: "ICICI BANK LIMITED",
  },
  {
    payment_code: 3023,
    Bank: "IDBI Bank",
  },
  {
    payment_code: 3024,
    Bank: "IDFC FIRST Bank",
  },
  {
    payment_code: 3024,
    Bank: "IDFC First Bank Ltd",
  },
  {
    payment_code: 3026,
    Bank: "Indian Bank",
  },
  {
    payment_code: 3027,
    Bank: "Indian Overseas Bank",
  },
  {
    payment_code: 3028,
    Bank: "IndusInd Bank",
  },
  {
    payment_code: 3028,
    Bank: "INDUSIND BANK LTD",
  },
  {
    payment_code: 3029,
    Bank: "Jammu and Kashmir Bank",
  },
  {
    payment_code: 3029,
    Bank: "JAMMU AND KASHMIR BANK LIMITED",
  },
  {
    payment_code: 3030,
    Bank: "Karnataka Bank Ltd",
  },
  {
    payment_code: 3031,
    Bank: "Karur Vysya Bank",
  },
  {
    payment_code: 3032,
    Bank: "Kotak Mahindra Bank",
  },
  {
    payment_code: 3032,
    Bank: "KOTAK MAHINDRA BANK LIMITED",
  },
  {
    payment_code: 3033,
    Bank: "Laxmi Vilas Bank - Retail Net Banking",
  },
  {
    payment_code: 3038,
    Bank: "Punjab National Bank - Retail Banking",
  },
  {
    payment_code: 3038,
    Bank: "PUNJAB NATIONAL BANK",
  },
  {
    payment_code: 3039,
    Bank: "RBL Bank",
  },
  {
    payment_code: 3039,
    Bank: "RBL Bank Limited",
  },
  {
    payment_code: 3040,
    Bank: "Saraswat Bank",
  },
  {
    payment_code: 3040,
    Bank: "SARASWAT COOPERATIVE BANK LIMITED",
  },
  {
    payment_code: 3042,
    Bank: "South Indian Bank",
  },
  {
    payment_code: 3044,
    Bank: "State Bank Of India",
  },
  {
    payment_code: 3054,
    Bank: "UCO Bank",
  },
  {
    payment_code: 3055,
    Bank: "Union Bank of India",
  },
  {
    payment_code: 3058,
    Bank: "Yes Bank Ltd",
  },
  {
    payment_code: 3058,
    Bank: "Yes Bank",
  },
  {
    payment_code: 3058,
    Bank: "YES BANK LIMITED",
  },
  {
    payment_code: 3086,
    Bank: "Shivalik Bank",
  },
  {
    payment_code: 3086,
    Bank: "Shivalik Small Finance Bank Limited",
  },
  {
    payment_code: 3087,
    Bank: "AU Small Finance Bank",
  },
  {
    payment_code: 3087,
    Bank: "AU SMALL FINANCE BANK LIMITED",
  },
  {
    payment_code: 3088,
    Bank: "Bandhan Bank - Retail Banking",
  },
  {
    payment_code: 3088,
    Bank: "BANDHAN BANK LIMITED",
  },
  {
    payment_code: 3089,
    Bank: "Utkarsh Small Finance Bank",
  },
  {
    payment_code: 3090,
    Bank: "The Surat Peoples Co-operative Bank Limited",
  },
  {
    payment_code: 3090,
    Bank: "THE SURAT DISTRICT COOPERATIVE BANK LIMITED",
  },
  {
    payment_code: 3090,
    Bank: "THE SURATH PEOPLES COOPERATIVE BANK LIMITED",
  },
  {
    payment_code: 3091,
    Bank: "Gujarat State Co-operative Bank Limited",
  },
  {
    payment_code: 3091,
    Bank: "THE GUJARAT STATE COOPERATIVE BANK LIMITED",
  },
  {
    payment_code: 3092,
    Bank: "HSBC Retail NetBanking",
  },
  {
    payment_code: 3092,
    Bank: "HSBC BANK",
  },
  {
    payment_code: 3098,
    Bank: "Capital Small Finance Bank",
  },
  {
    payment_code: 3098,
    Bank: "CAPITAL SMALL FINANCE BANK LIMITED",
  },
  {
    payment_code: 3115,
    Bank: "SBM Bank India",
  },
  {
    payment_code: 3115,
    Bank: "SBM BANK INDIA LIMITED",
  },
  {
    payment_code: 3117,
    Bank: "The Sutex Co-op Bank Ltd",
  },
  {
    payment_code: 3117,
    Bank: "SUTEX COOPERATIVE BANK LIMITED",
  },
];
