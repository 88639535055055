import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import * as CONSTANTS from "../Utils/constant";
import * as API from "../Utils/Services";
import Loader from "../CommonComponents/Loader";
const AuthGuard = () => {
  const [params, useParam] = useState(useParams());
  // var isAuthenticated = false;
  const [location, setLocation] = useState(useLocation());
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  let getHeader = {
    "x-authorization-token": params?.authToken,
  };
  let postHeader = {
    UserName: localStorage.getItem("clientID"),
    Authorization: `Bearer ` + params?.authToken,
    'P-DeviceType': "web",
  };
  useEffect(() => {
    setLoading(true);
    API.PostMethod(
      "v1/getProfile", { clientId: params?.clientId }, postHeader
    )
      .then((res) => {
        setLoading(false);
        if (res?.data != null) {
          setisAuthenticated(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setisAuthenticated(false);
      });
  }, [params]);

  return loading ? (
    <Loader />
  ) : isAuthenticated === false ? (
    <Navigate to="/profile-not-found" />
  ) : (
    <Outlet />
  );
};

export default AuthGuard;
